import { Notice } from '@/models';
import { reactive } from 'vue';

export const notices = reactive<Notice[]>([]);

export const randomId = () => {
	const generated = Math.floor(Math.random() * 16 ** 8).toString(16);
	return !notices.find(e => e.id === generated) ? generated : randomId();
};

export const dismiss = (noticeId: string) => {
	const index = notices.findIndex(e => e.id === noticeId);
	if (index > -1) notices.splice(index, 1);
};

export const add = (notice: Notice, timeout = 5000) => {
	if (!notice.id) notice.id = randomId();
	notices.push(notice);

	setTimeout(() => {
		dismiss(notice.id);
	}, timeout);
};
