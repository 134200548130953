import { createApp } from 'vue';
import { VueSignalR } from '@quangdao/vue-signalr';
import App from './App.vue';
import router from './router';
import { HubsUrl } from './config/api';
import { AuthService, DisconnectedService } from './services';

const app = createApp(App);

app.use(VueSignalR, {
	url: `${HubsUrl}/game`,
	accessTokenFactory: AuthService.getIdToken,
	disconnected() {
		DisconnectedService.disconnect();
	}
});

app.use(router).mount('#app');
