/** TODO:
 * Current implementation utilizes a hack of looping a fake video playing in the background
 * because there are no good widely-supported alternatives. However, the WakeLock API is in
 * the process of being implemented bny major browsers and support should be monitored.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/WakeLock
 */

import NoSleep from 'nosleep.js';

export const keepAwake = async () => {
	const noSleep = new NoSleep();

	if (!('wakeLock' in window.navigator)) {
		const enableNoSleep = () => {
			document.removeEventListener('click', enableNoSleep, false);
			noSleep.enable();
		};
		document.addEventListener('click', enableNoSleep, false);
		return;
	}

	try {
		await (window.navigator as any).wakeLock.request('screen');
	} catch (err) {
		// the wake lock request fails - usually system related, such being low on battery
		console.log(`${err.name}, ${err.message}`);
	}
};
