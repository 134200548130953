import { ref } from 'vue';
import * as backgrounds from '@/config/backgrounds';

const background = ref('');

export const preload = () => {
	for (const bg of Object.values(backgrounds)) {
		const img = new Image();
		img.src = bg;
		img.onload = () => img.remove();
		document.body.appendChild(img);
	}
};

export const set = (url: string) => (background.value = `url(${url})`);

export const get = () => background;
