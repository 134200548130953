
import { DisconnectedService, NoticeService } from '@/services';
import { computed, defineComponent, ref } from 'vue';
import { useSignalR } from '@quangdao/vue-signalr';
import { NoticeSent } from '@/config/actions';

export default defineComponent({
	setup() {
		const signalr = useSignalR();
		const unloaded = ref(false);
		const disconnected = DisconnectedService.getDisconnected();
		const notices = NoticeService.notices;

		const connectionError = computed(
			() => disconnected.value && !unloaded.value
		);

		const dismissNotice = (noticeId: string) => NoticeService.dismiss(noticeId);

		signalr.on(NoticeSent, notice => {
			NoticeService.add(notice);
		});

		window.addEventListener('beforeunload', () => {
			unloaded.value = true;
		});

		return { dismissNotice, connectionError, notices };
	}
});
