import { AuthService } from '@/services';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// Route Names
export const HomeRoute = Symbol('Home');
export const FacilitatorRoute = Symbol('Facilitator');
export const PlayerRoute = Symbol('Player');
export const SubscribeRoute = Symbol('Subscribe');

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: HomeRoute,
		component: () => import('../views/Player/Index.vue')
	},
	{
		path: '/host',
		name: FacilitatorRoute,
		component: () => import('../views/Facilitator/Index.vue')
	},
	{
		path: '/subscribe',
		name: SubscribeRoute,
		component: () => import('../views/Subscribe/Index.vue')
	},
	{
		path: '/tests/scenario',
		name: 'ScenarioTest',
		component: () => import('../views/Tests/ScenarioTest.vue')
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, _, next) => {
	if (to.path === '/login') {
		AuthService.handleLoginRedirect()
			.then(() => (window.location.href = '/host'))
			.catch(error => {
				console.log(error);
				next('/host');
			});

		return;
	}

	if (to.path === '/logout') {
		AuthService.logout();

		return;
	}

	if (to.path === '/logout-callback') {
		AuthService.handleLogoutRedirect()
			.then(() => next('/host'))
			.catch(error => {
				console.log(error);
				next('/host');
			});

		return;
	}

	next();
});

export default router;
