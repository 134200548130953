import {
	Player,
	JoinGameModel,
	Category,
	Session,
	Notice,
	GameStats
} from '@/models';
import { Scenario } from '@/models/scenario';
import { HubCommandToken, HubEventToken } from '@quangdao/vue-signalr';

export const ResumeSession: HubCommandToken<void, Session> = 'ResumeSession';
export const StartSession: HubCommandToken<void, Session> = 'StartSession';
export const JoinSession: HubCommandToken<JoinGameModel, Player> =
	'JoinSession';
export const RejoinSession: HubCommandToken<number, Player> = 'RejoinSession';
export const StartRound: HubCommandToken<void> = 'StartRound';
export const SelectCategory: HubCommandToken<number> = 'SelectCategory';
export const GetCategories: HubCommandToken<void, Category[]> = 'GetCategories';
export const SkipScenario: HubCommandToken<void> = 'SkipScenario';
export const SelectScenario: HubCommandToken<number> = 'SelectScenario';
export const ToggleHand: HubCommandToken<void> = 'ToggleHand';
export const ToggleScore: HubCommandToken<number> = 'ToggleScore';
export const EndGame: HubCommandToken<void> = 'EndGame';
export const TimeOut: HubCommandToken<void> = 'TimeOut';

export const NoticeSent: HubEventToken<Notice> = 'NoticeSent';
export const PlayerJoined: HubEventToken<Player> = 'PlayerJoined';
export const PlayerLeft: HubEventToken<number> = 'PlayerLeft';
export const PlayerSelected: HubEventToken<Player> = 'PlayerSelected';
export const PlayerUpdated: HubEventToken<Player> = 'PlayerUpdated';
export const CategorySelected: HubEventToken<Category> = 'CategorySelected';
export const ScenarioPrepared: HubEventToken<Scenario> = 'ScenarioPrepared';
export const ScenarioSelected: HubEventToken<Scenario> = 'ScenarioSelected';
export const HandToggled: HubEventToken<Player> = 'HandToggled';
export const ScoreToggled: HubEventToken<Player> = 'ScoreToggled';
export const SessionClosed: HubEventToken<void> = 'SessionClosed';
export const SessionUpdated: HubEventToken<Session> = 'SessionUpdated';
export const GameOver: HubEventToken<GameStats> = 'GameOver';
