import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_error_handler = _resolveComponent("error-handler")!

  return (_openBlock(), _createElementBlock("div", {
    class: "app",
    style: _normalizeStyle({ '--background-image': _ctx.background })
  }, [
    _createVNode(_component_router_view),
    _createVNode(_component_error_handler)
  ], 4))
}