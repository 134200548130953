import { AuthCookieDomainUrl, AuthUrl, ClientId } from '@/config/auth';
import { CookieStorage } from 'cookie-storage';
import {
	IdTokenClaims,
	UserManager,
	UserManagerSettings,
	WebStorageStateStore
} from 'oidc-client-ts';

const expiration = 7 * 24 * 60 * 60 * 1000;
const cookieStorage = new CookieStorage({
	domain: AuthCookieDomainUrl,
	expires: new Date(Date.now() + expiration)
});

const settings: UserManagerSettings = {
	userStore: new WebStorageStateStore({ store: cookieStorage }),
	stateStore: new WebStorageStateStore({ store: cookieStorage }),
	authority: AuthUrl,
	client_id: ClientId,
	redirect_uri: `${window.location.origin}/login`,
	post_logout_redirect_uri: `${window.location.origin}/logout-callback`,
	response_type: 'code',
	response_mode: 'query',
	scope: 'openid email profile',
	automaticSilentRenew: true,
	metadata: {
		issuer: `${AuthUrl}/`,
		authorization_endpoint: `${AuthUrl}/authorize`,
		token_endpoint: `${AuthUrl}/oauth/token`,
		userinfo_endpoint: `${AuthUrl}/userinfo`,
		jwks_uri: `${AuthUrl}/.well-known/jwks.json`,
		end_session_endpoint: `${AuthUrl}/v2/logout`
	}
};

const userManager = new UserManager(settings);

export const login = (): void => {
	userManager.signinRedirect().catch(error => console.log(error));
};

export const logout = (): void => {
	userManager.signoutRedirect().catch(error => console.log(error));
};

export const isLoggedIn = async (): Promise<boolean> => {
	const user = await userManager.getUser();
	return !!user && !user.expired;
};

export const handleLoginRedirect = async (): Promise<void> => {
	await userManager.signinRedirectCallback();
};

export const handleLogoutRedirect = async (): Promise<void> => {
	await userManager.signoutRedirectCallback();
	await userManager.clearStaleState();
};

const getUser = async () => await userManager.getUser();

export const getIdToken = async (): Promise<string> =>
	(await getUser())?.id_token;

export const getUserProfile = async (): Promise<IdTokenClaims> =>
	(await getUser())?.profile;
