import { ApiUrl } from '@/config/api';
import { User } from '@/models';
import axios, { AxiosResponse } from 'axios';
import * as AuthService from './auth';

const $api = axios.create({
	baseURL: ApiUrl
});

$api.interceptors.request.use(async config => {
	const accessToken = await AuthService.getIdToken();
	config.headers['Authorization'] = `Bearer ${accessToken}`;
	return config;
});

export const getWithResponse = <T>(
	url: string,
	data?: any
): Promise<AxiosResponse<T>> => $api.get(url, data);

export const get = async <T>(url: string, data?: any): Promise<T> =>
	(await getWithResponse<T>(url, data)).data;

export const postWithResponse = <T>(
	url: string,
	data?: any
): Promise<AxiosResponse<T>> => $api.post(url, data);

export const post = async <T>(url: string, data?: any): Promise<T> =>
	(await postWithResponse<T>(url, data)).data;

export const getUser = (): Promise<User> => get<User>('/auth');
