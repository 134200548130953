
import { useSignalR } from '@quangdao/vue-signalr';
import { defineComponent } from 'vue';
import { SessionClosed } from './config/actions';
import { HomeBackground } from './config/backgrounds';
import { BackgroundService, ScreenWakeService } from './services';
import ErrorHandler from './views/shared/ErrorHandler.vue';

export default defineComponent({
	setup() {
		const background = BackgroundService.get();
		const signalr = useSignalR();

		BackgroundService.preload();
		BackgroundService.set(HomeBackground);

		ScreenWakeService.keepAwake();

		signalr.on(SessionClosed, () => signalr.connection.stop());

		return { background };
	},
	components: { ErrorHandler }
});
